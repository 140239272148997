import { loadRegistrationPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import type { Handler } from '../types';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';
import type { RegistrationPage, RegistrationPageResponse, RegistrationRouteData } from './types';

const handler: Handler<RegistrationRouteData, RegistrationPage> = (routeData, state$, { api }) =>
  api.graphApi<RegistrationPageResponse>(loadRegistrationPageQuery).pipe(
    map(({ pages, profile }) => {
      const page = pages.registration;
      if (!page)
        return null;

        //TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
      page.templateFields = profile.registrationFields;
      page.vatRegistrationTypes = profile.vatRegistrationTypes;

      page.component = PageComponentNames.Registration;
      page.backTo = getBackTo(state$, [
        RouteName.Login,
        RouteName.Registration,
      ], routeData.params && routeData.params.language);

      return { page };
    }),
    initSystemPageContent(),
  );

export default handler;
