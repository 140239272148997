import {
  PRODUCT_POPUP_RECEIVED,
  PRODUCT_POPUP_CALCULATED_FIELDS_LOADED,
} from './actions';

import { createReducer } from 'utils/redux';

//3.3 UOM Calculation
export default createReducer(null, {
  [PRODUCT_POPUP_RECEIVED]: onProductPopupReceived,
  [PRODUCT_POPUP_CALCULATED_FIELDS_LOADED]: onPopupProductLoaded,
});

function onProductPopupReceived(state, action) {
  return {
    ...state,
    product: {
      ...action.payload,
    },
  };
}

function onPopupProductLoaded(state, action) {
  const stateVariants = state.product && state.product.variants;
  const { variants } = action.payload;

  if (variants) {
    for (const variant of variants) {
      const stateVariant = stateVariants.find(v => v.id === variant.id);
      if (stateVariant)
        variant.bomComponents = stateVariant.bomComponents;
    }
  }

  return {
    ...state,
    product: {
      ...state.product,
      ...action.payload,
      loaded: true,
    },
  };
}

