import PropTypes from 'prop-types';
import { AbilityState } from './constants';

export const abilityPropType = PropTypes.oneOf([
    AbilityState.Unauthorized,
    AbilityState.Available,
    AbilityState.NotAvailable,
    AbilityState.TemporaryNotAvailable,
]);

export const abilitiesPropType = PropTypes.arrayOf(abilityPropType);

export {
    login,
    register,
    logout,
    representCustomer,
    stopRepresentingCustomer,
    loadUserAbilities,
    USER_LOGOUT,
    createProspect,
    USER_PROFILE_UPDATED,
    userProfileUpdated,
    resetLoginFailed,
    resetImpersonationFailed,
    reloadUser,
    updateMinMax,
    updateCityPostal, //214457_Account_Creation
} from './actions';

export { USER_ANON_EXPIRED } from './broadcastActions';

export { CustomerType, UserType, ShopAccountType } from './constants';
