import { QUICK_ORDER_LINES_RECEIVED, QUICK_ORDER_CLEARED, QUICK_ORDER_POPUP_MODE } from '../actions.quickOrder';
import { createReducer } from 'utils/redux';
import { generateKey } from 'utils/helpers';
import { initialState } from './constants';

export default createReducer(initialState, {
  [QUICK_ORDER_LINES_RECEIVED]: onLinesReceived,
  [QUICK_ORDER_CLEARED]: onQuickOrderClear,
  [QUICK_ORDER_POPUP_MODE]: onQuickOrderPopup,
});

function onLinesReceived(state, action) {
  const productLines = action.payload.lines;

  let selectedLineId;

  if (productLines && productLines.list) {
    for (const line of productLines.list) {
      line.id = line.id || generateKey();

      if (line.subLines) {
        for (const subLine of line.subLines) {
          subLine.id = subLine.id || generateKey();
        }
      }
    }

    if (productLines.list.length)
      selectedLineId = productLines.list[0].id;
  }

  return {
    ...state,
    lastModifiedLineId: selectedLineId,
    model: {
      ...state.model,
      productLines,
      serviceLines: [],
    },
    isQuickOrderMode: true,
  };
}

function onQuickOrderClear(state) {
  return { ...state, isQuickOrderMode: false };
}

//3.3. UOM Calculator
function onQuickOrderPopup(state, action) {

  var enabled = action.payload.enabled;
  return { ...state, isQuickOrderPopupMode: enabled };
}