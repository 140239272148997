import {
  createProductPageQuery,
  updateProductCalculatedFieldsQuery,
} from './queries';
import {
  PRODUCT_POPUP_REQUESTED,
  productPopupReceived,
  UPDATE_PRODUCT_CALCULATED_FIELDS,
  productCalculatedFieldsLoaded,
} from './actions';

import { switchMap, map, takeUntil, pluck, } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import { merge } from 'rxjs';
import { ProductSpecificationFilter } from 'behavior/products/product';

//3.3 UOM Calculation
const productPopupEpic = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  const scope = 'CLIENT';
  const state = state$.value;
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const options = {
    relatedProducts: {
      includeLargeImages: true,
    },
    scope,
    isInsiteEditor: state.insiteEditor.initialized,
  };

  const query = createProductPageQuery(options);
  const variables = {
    specificationFilter: ProductSpecificationFilter.ForDetails,
    detailsPreset: 'Details',
    loadRelatedProductsCategories: false,
  };

  const onProductPopupRequested$ = action$.pipe(
    ofType(PRODUCT_POPUP_REQUESTED),
    switchMap((action) => {
      return api.graphApi(query, { ...variables, productId: action.payload.productId }).pipe(
        pluck('pages', 'product', 'product'),
        map((product) => {
          return productPopupReceived(product);
        }),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$))
    }),
  );

  const onFieldsRequested$ = action$.pipe(
    ofType(UPDATE_PRODUCT_CALCULATED_FIELDS),
    switchMap(action => api.graphApi(updateProductCalculatedFieldsQuery, action.payload).pipe(
      map((data) => {

        const product = data.catalog.products.products[0];
        return productCalculatedFieldsLoaded(product);
      }),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  return merge(
    onProductPopupRequested$,
    onFieldsRequested$,
  );
};

export default productPopupEpic;