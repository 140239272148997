export const PRODUCT_POPUP_REQUESTED = 'PRODUCT/POPUP_REQUESTED';
export const requestProductPopup = (productId, variantId) => ({
  type: PRODUCT_POPUP_REQUESTED,
  payload: { productId, variantId },
});

export const PRODUCT_POPUP_RECEIVED = 'PRODUCT/POPUP_RECEIVED';
export const productPopupReceived = product => ({
  type: PRODUCT_POPUP_RECEIVED,
  payload: product,
});

export const UPDATE_PRODUCT_CALCULATED_FIELDS = 'PRODUCT/POPUP/UPDATE/CALCULATED_FIELDS';
export const updateCalculatedFields = options => ({
  type: UPDATE_PRODUCT_CALCULATED_FIELDS,
  payload: { options },
});

//3.3 UOM Calculation
export const PRODUCT_POPUP_CALCULATED_FIELDS_LOADED = 'PRODUCT/POPUP/CALCULATED_FIELDS/LOADED';
export const productCalculatedFieldsLoaded = product => ({
  type: PRODUCT_POPUP_CALCULATED_FIELDS_LOADED,
  payload: product,
});

export const SALES_AGREEMENT_REQUESTED = 'PRODUCT/POPUP/SALES_AGREEMENT_REQUESTED';
export const requestSalesAgreement = (agreementId, productId) => ({
  type: SALES_AGREEMENT_REQUESTED,
  payload: { agreementId, productId },
});

export const SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED = 'PRODUCT/POPUP/SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED';
export const changeProductVariantForSalesAgreement = (variantId, canViewUom, allowUOMSelection) => ({
  type: SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED,
  payload: { variantId, canViewUom, allowUOMSelection },
});