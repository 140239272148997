import {
  RegistrationAction,
  REGISTRATION_PROCESSED,
  REGISTRATION_TEMPLATEFIELDS_RECEIVED,
  REGISTRATION_PROCESSED_CELUDANUMBER
} from './actions';
import type { TemplateFields, RegistrationResult, VatRegistrationTypes } from './types';

//TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
type State = {
    registrationResult: RegistrationResult;
    vatRegistrationTypes: VatRegistrationTypes | null | undefined;
    templateFields: TemplateFields | null | undefined;
};

const initialState = {
    registrationResult: {
        failureText: null,
        isRegistered: false,
    },
    templateFields: null,
    vatRegistrationTypes: null,
};

export default (state = initialState, action: RegistrationAction): State => {
    
  switch (action.type) {
    case REGISTRATION_PROCESSED:
          return { ...state, registrationResult: action.payload };
      case REGISTRATION_PROCESSED_CELUDANUMBER:
          //TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
          return { ...state, registrationResult: action.payload };
      case REGISTRATION_TEMPLATEFIELDS_RECEIVED:
          //TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
          const templateFields = action.payload?.registrationFields;
          const vatRegistrationTypes = action.payload?.vatRegistrationTypes;

          return { ...state, templateFields, vatRegistrationTypes };
    default:
      return state;
  }
};