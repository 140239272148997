import type { RegistrationResult, TemplateFields, VatRegistrationTypes } from './types';

export const REGISTRATION_PROCESSED = 'REGISTRATION_PROCESSED' as const;
export const registrationProcessed = (result: RegistrationResult) => ({
  type: REGISTRATION_PROCESSED,
  payload: result,
});

//TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
export const REGISTRATION_TEMPLATEFIELDS_RECEIVED = 'REGISTRATION_TEMPLATEFIELDS_RECEIVED' as const;
export const templateFieldsReceived = (payload: { registrationFields: TemplateFields | null, vatRegistrationTypes: VatRegistrationTypes | null } | null) => ({
    type: REGISTRATION_TEMPLATEFIELDS_RECEIVED,
    payload: payload,
});

//TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
export const REGISTRATION_PROCESSED_CELUDANUMBER = 'REGISTRATION_PROCESSED_CELUDANUMBER' as const;
export const celudaNumberResult = (result: RegistrationResult) => ({
    type: REGISTRATION_PROCESSED_CELUDANUMBER,
    payload: result,
});

export type RegistrationAction = ReturnType<
  | typeof registrationProcessed
  | typeof templateFieldsReceived
  | typeof celudaNumberResult
>;