import {
  USER_AUTHENTICATED,
  USER_ABILITIES_LOADED,
  USER_PROFILE_UPDATED,
  LOGIN_FAILED,
  LOGIN_FAILED_RESET,
  USER_LOGIN,
  IMPERSONATION_FAILED,
  IMPERSONATION_FAILED_RESET,
  REPRESENT_CUSTOMER,
  UPDATE_MINMAX, //TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
  UPDATE_CITYPOSTAL, //214457_Account_Creation
} from './actions';
import { OFFLINE_MODE_CHANGED } from 'behavior/app';
import { createReducer } from 'utils/redux';

const initialState = {
  id: null,
  name: null,
  email: null,
  type: null,
  isAuthenticated: false,
  shopAccountType: null,
  isImpersonating: false,
  pricesInclTax: null,
  currencyId: null,
  customer: null,
  abilities: {},
  expiredAbilities: [],
  initialized: false,
  loginFailed: false,
  impersonationFailed: false,
};

//TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
export default createReducer(initialState, {
  [USER_AUTHENTICATED]: onUserAuthenticated,
  [OFFLINE_MODE_CHANGED]: onOfflineModeChanged,
  [USER_ABILITIES_LOADED]: onAbilitiesLoaded,
  [USER_PROFILE_UPDATED]: onUserProfileUpdated,
  [LOGIN_FAILED]: onLoginFailed,
  [LOGIN_FAILED_RESET]: resetLoginFailureStatus,
  [USER_LOGIN]: resetLoginFailureStatus,
  [IMPERSONATION_FAILED]: onImpersonationFailed,
  [IMPERSONATION_FAILED_RESET]: resetFailedImpersonationStatus,
  [REPRESENT_CUSTOMER]: resetFailedImpersonationStatus,
  [UPDATE_MINMAX]: updateMinMax,
  [UPDATE_CITYPOSTAL]: updateCityPostal, //214457_Account_Creation
});

//TICKET 139013 - 3.7. Customer registration � RNC/Cedula number
function updateMinMax(state, action) {
  return {
    ...state, ...action.payload,
  };
}

//214457_Account_Creation
function updateCityPostal(state, action) {
  debugger
  return {
    ...state, ...action.payload,
  };
}

function onUserAuthenticated(state, action) {
  return {
    ...action.payload,
    initialized: true,
    abilities: action.payload.abilities || state.abilities,
    expiredAbilities: action.payload.abilities ? [] : Object.keys(state.abilities),
  };
}

function onOfflineModeChanged(state, _action) {
  return {
    ...state,
    expiredAbilities: Object.keys(state.abilities),
  };
}

function onAbilitiesLoaded(state, action) {
  const loadedKeys = Object.keys(action.payload);
  const newExpiredAbilities = state.expiredAbilities.filter(k => !loadedKeys.includes(k));
  return {
    ...state,
    abilities: {
      ...state.abilities,
      ...action.payload,
    },
    expiredAbilities: newExpiredAbilities.length === state.expiredAbilities.length ? state.expiredAbilities : newExpiredAbilities,
  };
}

function onUserProfileUpdated(state = null, action) {
  return {
    ...state, ...action.payload,
  };
}

function onLoginFailed(state, _action) {
  return {
    ...state, loginFailed: true,
  };
}

function resetLoginFailureStatus(state, _action) {
  return state.loginFailed
    ? { ...state, loginFailed: false }
    : state;
}

function onImpersonationFailed(state, _action) {
  return {
    ...state, impersonationFailed: true,
  };
}

function resetFailedImpersonationStatus(state, _action) {
  return state.impersonationFailed
    ? { ...state, impersonationFailed: false }
    : state;
}
